import React, {createRef} from 'react';
import {Link} from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import '../assets/css/formStyle.css';
import classNames from "classnames";
import CircularProgress from '@material-ui/core/CircularProgress';
import green from '@material-ui/core/colors/green';
import grey from '@material-ui/core/colors/grey';
import {withStyles} from "@material-ui/core/styles";
import {isMobile} from "../util/Validator";
import {showError} from "../panel/components/Notifier";
import {compose} from "redux";
import {connect} from "react-redux";
import {
    resendForgetPassword, setDarkTheme,
    setForgotPasswordStep,
    updateCaptchaVersion,
    userForgetPassword,
    verifyUserForgotPassword,
} from "../actions";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {FORGETPASSWORD} from "../constants/Units";
import {fa2enNumbers} from "../util/Utilities";
import BrowserAlert from "./components/BrowserAlert";
import {BRANCH_ESSENTIALS} from "../panel/routes/data/constants";
import {DOMAIN} from "../constants/Path";
import ImageStorage from "../assets/ImageStorage";

const styles = theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrapper: {
        margin: theme.spacing(),
        position: 'relative',
    },
    buttonSuccess: {
        backgroundColor: green[500],
        color: grey[50],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    fabProgress: {
        color: green[500],
        position: 'absolute',
        top: -6,
        left: -6,
        zIndex: 1,
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
});


const emailRegex = RegExp(
    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
);

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.timer = 0;
    }

    state = {
        userInfo: {
            email: '',
            phone_number: '',
            password: '',
            repeatPassword: ''
        },
        formErrors: {
            captcha: "",
            email: "",
            password: "",
            repeatPassword: ''
        },
        refs: {
            captcha: createRef()
        },
        captcha: "",
        error: {},
        loading: false,
        success: false,
        activeStep: 0,
        formValid: {},
        formToken: '',
        userInputName: 'emailOrPhone',
        time: {},
        seconds: "",
        resendLeftSecs: 120,
        showRepeatPassword: false,
        showPassword: false

    };
    timer = undefined;

    handleReset = () => {
        this.setState({
            activeStep: 0,
            email: '',
            password: ''
        });
    };

    handleNext = (e) => {
        e.preventDefault();
        const {forgetPasswordStep} = this.props;
        if (forgetPasswordStep === 1) {
            const {userInfo, userInputName, captcha, formErrors} = this.state;
            if (!!formErrors[userInputName]) {
                showError(FORGETPASSWORD, 'form.invalid');
                return;
            }

            let fields = {
                captcha: captcha,
                // email: userInfo.email,
                // phone_number: userInfo.phone_number
                terminal_token: BRANCH_ESSENTIALS.TERMINAL_TOKEN,
                terminal_id: BRANCH_ESSENTIALS.TERMINAL_ID
            };
            fields[userInputName] = userInfo[userInputName];
            this.props.userForgetPassword(fields);
        } else if (forgetPasswordStep === 2) {
            const {userInfo, verifyCode, formErrors} = this.state;
            if (!!formErrors.password || !!formErrors.repeatPassword) {
                showError(FORGETPASSWORD, 'form.invalid');
                return;
            }
            this.props.verifyUserForgotPassword({
                token: this.props.forgetPasswordVerifyDetails.token,
                code: verifyCode,
                password: userInfo.password
            });

        }
    };

    startTimer() {
        const {forgetPasswordVerifyDetails} = this.props;
        const now = Date.now() / 1000;
        if (forgetPasswordVerifyDetails && forgetPasswordVerifyDetails.next_request > now && !this.timerStarted) {
            this.timer = setInterval(() => {
                const resendLeftSecs = this.props.forgetPasswordVerifyDetails.next_request - parseInt(Date.now() / 1000);
                this.setState({resendLeftSecs: Math.max(resendLeftSecs, 0)});
                if (resendLeftSecs <= 0)
                    this.stopTimer();
            }, 1000);
            this.timerStarted = true;
        }

    }

    stopTimer() {
        if (this.timerStarted && this.timer) {
            clearInterval(this.timer);
            this.timerStarted = false;
        }
    };

    componentWillUnmount() {
        this.stopTimer();
    }


    handleBack = () => {
        const {forgetPasswordStep} = this.props;
        this.props.setForgotPasswordStep(forgetPasswordStep - 1);
        this.setState({
            email: "",
            phone_number: "",
        });
    };

    handleChange = e => {
        // localStorage.clear();
        e.preventDefault();
        let {name, value} = e.target;
        let formErrors = {...this.state.formErrors};
        value = fa2enNumbers(value);

        switch (name) {
            case this.state.userInputName:
                formErrors.email = '';
                if (emailRegex.test(value)) {
                    this.state.userInputName = 'email';
                    this.state.userInfo.email = value;
                } else if (isMobile({input: value})) {
                    this.state.userInputName = 'phone_number';
                    this.state.userInfo.phone_number = value;
                } else {
                    formErrors.email = <IntlMessages id="error.content.email_mobile.invalid"/>;
                    this.state.userInputName = 'emailOrPhone';
                    this.state.userInfo.phone_number = '';
                    this.state.userInfo.email = '';
                }
                break;
            case "password":
                formErrors.password =
                    value.length < 8 ? <IntlMessages id="error.content.password.length"/> : "";
                // if(formErrors.repeatPassword)
                formErrors.repeatPassword = (this.state.userInfo['repeatPassword'] === value ?
                    "" :
                    <IntlMessages id="error.content.password_repeat.not_matched"/>);
                this.state.userInfo.password = value;
                break;
            case "repeat-password":
                formErrors.repeatPassword = (this.state.userInfo.password === value ?
                    "" :
                    <IntlMessages id="error.content.password_repeat.not_matched"/>);
                this.state.userInfo.repeatPassword = value;
                break;
            case "verifyCode":
                formErrors.verifyCode =
                    value.length < 5 ? <IntlMessages id="error.content.auth_code.invalid"/> : "";
                break;
            default:
                break;
        }
        this.setState({formErrors, [name]: value},);
    };

    secondsToTime(secs) {
        let seconds = secs;

        let obj = {
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({time: timeLeftVar});
    }

    handleResendCode = (e) => {
        e.preventDefault();
        this.props.resendForgetPassword({
            token: this.props.forgetPasswordVerifyDetails.token, terminal_token: BRANCH_ESSENTIALS.TERMINAL_TOKEN,
            terminal_id: BRANCH_ESSENTIALS.TERMINAL_ID
        });
    };

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (this.state.seconds == 0) {
            clearInterval(this.timer);
            // return <><Button color="secondary">jdfhjh</Button> </>
        }
    }

    handleMouseDownPassword = event => {
        event.preventDefault();
    };

    updateRecaptcha = () => {
        this.props.updateCaptchaVersion();
    }

    getVerifyCode() {
        const {forgetPasswordVerifyDetails} = this.props;
        return (
            <>
                <div className="hint-box">
                    <div className="hint-row">
                        {forgetPasswordVerifyDetails.field == "mobile" ?
                            <>لطفا کد ارسال شده به شماره <div style={{
                                direction: 'ltr',
                                display: 'inline-block'
                            }}>{forgetPasswordVerifyDetails.mobile}</div> را وارد نمایید</>
                            : forgetPasswordVerifyDetails.field == "email" ?
                                <>لطفا کد ارسال شده به ایمیل <div style={{
                                    direction: 'ltr',
                                    display: 'inline-block'
                                }}>{forgetPasswordVerifyDetails.email}</div> را وارد نمایید</>
                                : ""
                        }
                    </div>
                    <div className="gradient-bottom"/>
                </div>
                <div className="validity-wrapper">
                    <div className="validity-desc">
                        اعتبار تا :
                    </div>
                    <span className="validity-seconds">
                {" " + forgetPasswordVerifyDetails.code_validity / 60 + " دقیقه "}
            </span>
                </div>
                <TextField
                    type="text"
                    id="verifyCode"
                    name="verifyCode"
                    label={'کد تایید'}
                    autoComplete='new-password'
                    fullWidth
                    autoFocus={true}
                    inputProps={{ tabIndex: "1" ,  autoComplete: 'off'}}
                    // defaultValue={this.state.password}
                    // onChange={(event) => this.setState({password: event.target.value})}
                    margin="normal"
                    className="mt-1"
                    onChange={this.handleChange}
                    error={this.state.error.verifyCode}
                    variant="outlined"
                    size="small"
                />
                {this.state.resendLeftSecs === 0 ?
                    <div className="timer-wrapper">
                        <Link to="" onClick={this.handleResendCode}>
                            ارسال مجدد کد تایید
                        </Link>
                    </div>

                    :
                    <div className="timer-wrapper">
                        {this.state.resendLeftSecs === 0 ? "ارسال مجدد کد تایید" : "ارسال مجدد تا " + this.state.resendLeftSecs + " ثانیه"}
                    </div>
                }
                {forgetPasswordVerifyDetails.field == "email" ?
                    <div className="hint-box">
                        <div className="hint-row">
                            لطفا پوشه اسپم ایمیل خود را نیز بررسی نمائید.
                        </div>
                        <div className="gradient-bottom"/>
                    </div>
                    : ""
                }
                <div>
                    <TextField
                        type={this.state.showPassword ? 'text' : 'password'}
                        id="password"
                        name="password"
                        autoComplete='new-password'
                        label={'گذرواژه جدید '}
                        fullWidth
                        inputProps={{tabIndex: "2"}}
                        // defaultValue={this.state.password}
                        // onChange={(event) => this.setState({password: event.target.value})}
                        margin="normal"
                        className={"mt-1 " + (this.state.formErrors.password ? "error" : '')}
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.password}
                        variant="outlined"
                        size="small"
                        InputProps={{

                            startAdornment:
                                <IconButton
                                    tabIndex={-1}
                                    onClick={() => {
                                        this.handleClickShowPassword(1)
                                    }}
                                    onMouseDown={this.handleMouseDownPassword}
                                    style={{marginRight: "8px", padding: "0"}}
                                >
                                    {this.state.showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>


                        }}
                    />
                    {this.state.formErrors.password && (
                        <div style={{fontSize: "small", position: "absolute", marginTop: "-8px"}}>
                            <span className="small text-danger">{this.state.formErrors.password}</span>
                        </div>
                    )}
                </div>
                <div className='mt-3'>
                    <TextField
                        type={this.state.showRepeatPassword ? 'text' : 'password'}
                        id="repeat-password"
                        name="repeat-password"
                        label={'تکرار گذرواژه جدید'}
                        fullWidth
                        inputProps={{tabIndex: "3"}}
                        // defaultValue={this.state.password}
                        // onChange={(event) => this.setState({password: event.target.value})}
                        margin="normal"
                        className={"mt-1 " + (this.state.formErrors.repeatPassword ? "error" : '')}
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.repeatPassword}
                        variant="outlined"
                        size="small"
                        InputProps={{

                            startAdornment:
                                <IconButton
                                    tabIndex={-1}
                                    onClick={() => {
                                        this.handleClickShowPassword(2)
                                    }}
                                    onMouseDown={this.handleMouseDownPassword}
                                    style={{marginRight: "8px", padding: "0"}}
                                >
                                    {this.state.showRepeatPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>


                        }}
                    />
                    {this.state.formErrors.repeatPassword && (
                        <div style={{fontSize: "small", position: "absolute", marginTop: "-8px"}}>
                            <span className="small text-danger">{this.state.formErrors.repeatPassword}</span>
                        </div>
                    )}
                </div>
                <div style={{visibility: "hidden"}}>
                    <TextField
                        type="hidden"
                        id="verifyCode"
                        name="verifyCode"
                        value={this.state.formToken}

                    />
                </div>


            </>

        )
    }

    getUserInfo() {
        return (

            <>
                <BrowserAlert/>
                <div>
                    <TextField
                        id="emailOrPhoneNo"
                        label={<IntlMessages id="appModule.email-or-mobile"/>}
                        // value={this.state.userInfo.email}
                        fullWidth
                        inputProps={{tabIndex: "1"}}
                        autoFocus={true}
                        margin="normal"
                        className={this.state.formErrors.email ? "error" : null}
                        placeholder="Email or PhoneNumber"
                        type="text"
                        name={this.state.userInputName}
                        noValidate
                        onChange={this.handleChange}
                        error={!!this.state.formErrors.email}
                        variant="outlined"
                        size="small"
                    />
                    {this.state.formErrors.email && (
                        <div style={{fontSize: "small", position: "absolute", marginTop: "-8px"}}>
                            <span className="small text-danger">{this.state.formErrors.email}</span>
                        </div>

                    )}
                </div>
                <div className="captcha-input-wrapper">
                    <div className='captcha-wrapper'>
                        <IconButton
                            tabIndex={-1}
                            onClick={this.updateRecaptcha}
                        >
                            <img alt="alt" src={require("../assets/images/V2/refresh.svg")}/>
                        </IconButton>
                        <img src={'/core/api/captcha/forgot?v=' + this.props.captchaVersion}
                             alt='Forgot Password Captcha' style={{padding: '8px 8px 0px 8px'}}/>
                    </div>
                    <div>
                        <div className="sign-input-labels">
                            کد امنیتی:
                        </div>
                        <TextField
                            id="captcha"
                            // label={<IntlMessages id="appModule.captcha_input"/>}
                            // value={this.state.userInfo.email}
                            fullWidth
                            inputProps={{tabIndex: "3"}}
                            inputRef={this.state.refs.captcha}
                            autoComplete='off'
                            margin="normal"
                            className={this.state.formErrors.captcha.length > 0 ? "error" : null}
                            placeholder=""
                            type="number"
                            name='captcha'
                            noValidate
                            onChange={this.handleChange}
                            error={this.state.error.captcha}
                            variant="outlined"
                            size="small"
                        />
                        {this.state.formErrors.captcha.length > 0 && (
                            <div className="error-box">
                                <img alt="alt" src={require("../assets/images/V2/error.svg")}/>
                                {this.state.formErrors.captcha}
                            </div>
                        )}
                    </div>
                </div>
            </>
        )


    }

    handleClickShowPassword = (id) => {
        switch (id) {
            case  1 :
                this.setState({showPassword: !this.state.showPassword});
                break;
            case 2 :
                this.setState({showRepeatPassword: !this.state.showRepeatPassword});
                break;


        }

    };

    getStepContent(stepIndex) {
        switch (stepIndex) {
            case 1:
                this.stopTimer();
                return this.getUserInfo();
            case 2:
                this.startTimer();
                return this.getVerifyCode();
            default:
                return <IntlMessages id="appModule.loginSuccessfully"/>;
        }
    }

    changeDarkMode = (setDark) => {
        const {darkTheme} = this.props;
        if (setDark !== darkTheme) {
            this.props.setDarkTheme();
            const body = document.body.classList;
            body.toggle('dark-theme')
        }
    }

    render() {
        const {classes, forgetPasswordStep, sendingForgetPasswordRequest, darkTheme} = this.props;
        return (
            <div className="w-100 h-100">
                <div className="app-login-main-content h-100">
                    <div className="app-logo-content  align-items-center">
                        <div className="app-logo-content-header">
                            <div onClick={(e) => darkTheme ? this.changeDarkMode(false) : this.changeDarkMode(true)}
                                 className="change-theme-wrapper">
                                {ImageStorage.header.darkMode(darkTheme, 'mode-btn')}
                            </div>
                            <Link className="logo-wrapper" to="/home" title="Lock">
                                {darkTheme ? <img alt="alt" src={require("../assets/images/logo/Logo.png")}/> :
                                    <img alt="alt" src={require("../assets/images/logo/LogoLight.png")}/>}
                            </Link>

                        </div>

                        <img className="lock-img" src={require("assets/images/V2/signlock.svg")}

                             alt="Lock" title="Lock"/>
                        <div className="hint-box">
                            <div className="hint-row">
                            درصورتیکه قبلا ثبت نام نموده اید و برای نخستین بار است که وارد پنل جدید می شوید, لطفا از
                                طریق
                                منوی <Link to="/reset-password">فراموشی رمزعبور</Link> اقدام به بازیابی کلمه عبور
                                حسابتان کنید.

                            </div>
                            <div className="gradient-bottom"/>
                        </div>
                    </div>
                    <div className="app-login-content">
                        <div className="app-login-content-inner">
                            <div className="app-login-header">
                                <div className="sign-title">
                                    <IntlMessages id="title.forget-password"/>
                                </div>
                                <div className="sign-desc">
                                    <div>
                                        ثبت نام نکرده اید؟
                                    </div>
                                    <div>
                                        <Link to="/signup" title="Reset Password">
                                            ثبت نام کنید
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="login-form">
                                <form>
                                    <fieldset>
                                        {this.getStepContent(forgetPasswordStep)}

                                        {forgetPasswordStep === 1 ?
                                            <div className="confirm-btn-wrapper">
                                                <Button
                                                    variant="contained"
                                                    className="confirm-btn"
                                                    disabled={sendingForgetPasswordRequest}
                                                    onClick={this.handleNext}
                                                    fullWidth
                                                    type="submit"
                                                >
                                                    <IntlMessages id="appModule.send"/>
                                                </Button>
                                                {sendingForgetPasswordRequest &&
                                                    <CircularProgress size={24}
                                                                      className={classes.buttonProgress}/>}
                                            </div>
                                            :
                                            <div className="buttons-wrapper">
                                                <div className="confirm-btn-wrapper">
                                                    <Button
                                                        variant="contained"
                                                        className="confirm-btn"
                                                        disabled={sendingForgetPasswordRequest}
                                                        onClick={this.handleNext}
                                                        fullWidth
                                                        type="submit"
                                                    >
                                                        تایید
                                                    </Button>
                                                    {sendingForgetPasswordRequest &&
                                                        <CircularProgress size={24}
                                                                          className={classes.buttonProgress}/>}
                                                </div>
                                                <div className="cancel-btn-wrapper">
                                                    <Button
                                                        onClick={this.handleBack}
                                                        className="cancel-btn"
                                                        fullWidth
                                                    >
                                                        <IntlMessages id="appModule.back"/>
                                                    </Button>
                                                </div>
                                            </div>
                                        }
                                    </fieldset>
                                </form>
                            </div>
                            <div className="home-page">
                                <Link to="./home">
                                    برو به صفحه ی اصلی
                                </Link>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

// export default withStyles(styles)(ForgotPassword);
const mapStateToProps = ({auth, settings}) => {
    const {darkTheme} = settings;
    const {
        sendingForgetPasswordRequest,
        tokenReceiveTime,
        forgetPasswordStep,
        forgetPasswordVerifyDetails,
        captchaVersion
    } = auth;
    return {
        sendingForgetPasswordRequest,
        tokenReceiveTime,
        forgetPasswordStep,
        forgetPasswordVerifyDetails,
        captchaVersion,
        darkTheme
    };
};

export default compose(
    connect(
        mapStateToProps,
        {
            setDarkTheme,
            userForgetPassword,
            verifyUserForgotPassword,
            setForgotPasswordStep,
            updateCaptchaVersion,
            resendForgetPassword
        },
    ),
    withStyles(styles)
)(ForgotPassword)






