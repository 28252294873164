import {
    EMAIL_CODE_SENT,
    EMAIL_VERIFIED,
    FETCH_DONE,
    FETCHING_DATA,
    LAST_AUTH_FETCHED, ONLINE_DEPOSIT_REQUESTED,
    PHONE_CODE_SENT,
    PHONE_VERIFIED, REQUESTING_SELFIE, SELFIE_REQUEST_FAILED, SELFIE_REQUESTED,
    SET_AUTH_STEP,
    UPLOAD_PROGRESS, USE_GATEWAY, USE_SELFIE_GATEWAY
} from "../constants/ActionTypes";

const INIT_STATE = {
    submitting: false,
    success: false,
    activeStep: 0,
    formToken: '',
    progress: 0,
    lastAuthRequest: {},
    requestingSelfie: false,
    gateway: {},
    gatewayUsed: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case UPLOAD_PROGRESS: {
            return {...state, progress: action.payload.progress };
        }
        case LAST_AUTH_FETCHED: {
            return {...state, lastAuthRequest: action.payload.data ?? {} };
        }
        case FETCHING_DATA: {
            return {...state, submitting: true };
        }
        case FETCH_DONE: {
            return {...state, submitting: false };
        }
        case EMAIL_CODE_SENT: {
            return {...state, submitting: false, success: true, activeStep: 1, formToken: action.payload.formToken};
        }
        case SET_AUTH_STEP: {
            return {...state, activeStep: action.payload.activeStep};
        }

        case EMAIL_VERIFIED: {
            return {...state, submitting: false, success: true, activeStep: 2, formToken: ''};
        }
        case PHONE_CODE_SENT: {
            return {...state, submitting: false, success: true, activeStep: 1, formToken: action.payload.formToken};
        }
        case PHONE_VERIFIED: {
            return {...state, submitting: false, success: true, activeStep: 2, formToken: ''};
        }
        case REQUESTING_SELFIE: {
            return {...state, requestingSelfie: true, gateway: {}, gatewayUsed: false};
        }
        case SELFIE_REQUEST_FAILED: {
            return {...state, requestingSelfie: false, gateway: {}, gatewayUsed: false};
        }
        case SELFIE_REQUESTED: {
            return {...state, requestingSelfie: false, gateway: action.payload, gatewayUsed: false};
        }
        case USE_SELFIE_GATEWAY: {
            return {...state, gatewayUsed: true};
        }
        default:
            return state;

    }
}

