import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';

import IntlMessages from 'util/IntlMessages';


class CollapsedMenu extends Component {

    componentDidMount() {
        const {history} = this.props;

        const pathname = `#${history.location.pathname}`;// get current path
        const mainMenu = document.getElementsByClassName('nav-item');
        for (let i = 0; i < mainMenu.length; i++) {
            mainMenu[i].onclick = function () {
                for (let j = 0; j < mainMenu.length; j++) {
                    if (mainMenu[j].classList.contains('active')) {
                        mainMenu[j].classList.remove('active')
                    }
                }
                this.classList.toggle('active');
            }
        }
        const subMenuLi = document.getElementsByClassName('nav-arrow');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function () {
                for (let j = 0; j < subMenuLi.length; j++) {
                    if (subMenuLi[j].classList.contains('active')) {
                        subMenuLi[j].classList.remove('active')
                    }
                }
                this.classList.toggle('active');
            }
        }
        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('active');
            } else {
                this.closest(activeLi, 'li').classList.add('active');
            }
            const parentNav = this.closest(activeNav, '.nav-item');
            if (parentNav) {
                parentNav.classList.add('active');
            }

        } catch (e) {

        }

    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }


    render() {
        return (
            <div className="app-main-menu d-md-block">
                <ul className="navbar-nav navbar-nav-mega flex-column">

                    <li className="nav-item">
                        <a href='#'>
                            <span className="nav-link"><IntlMessages id="menu.home"/></span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <Link to="panel/signin" className="header-btn">
                            <IntlMessages id="home.header.signin"/>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="panel/signup" className="header-btn">
                            <IntlMessages id="home.header.signup"/>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <a href='https://blog.novinbit.com/blog/'>
                            <span className="nav-link"><IntlMessages id="menu.blog"/></span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href='https://blog.novinbit.com/faq/'>
                            <span className="nav-link"><IntlMessages id="menu.frequently_questions"/></span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href='https://blog.novinbit.com/law/'>
                            <span className="nav-link"><IntlMessages id="menu.rules"/></span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href='https://blog.novinbit.com/contactus/'>
                            <span className="nav-link"><IntlMessages id="menu.link_us"/></span>
                        </a>
                    </li>

                </ul>
            </div>
    );
    }
}

export default withRouter(CollapsedMenu);