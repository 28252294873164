import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
// import Avatar from '@material-ui/core/Avatar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    MINI_DRAWER
} from '../../../constants/ActionTypes';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import AppNotification from '../AppNotification/index';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import IntlMessages from 'util/IntlMessages';
// import LanguageSwitcher from "../LanguageSwitcher";
import UserInfoPopup from "../UserInfo/UserInfoPopup";
import Badge from "@material-ui/core/Badge";
import {Button} from "@material-ui/core";
import {MOBILE_MAX_WIDTH, TABLET_MIN_WIDTH} from "../../../panel/routes/data/constants";
import {setDarkTheme, setDrawerType, switchLanguage, toggleCollapsedNav} from "../../../actions";
import {switchDepositDialog, switchWithdrawDialog} from "../../../actions/Wallet";
import ImageStorage from "../../../assets/ImageStorage";


class Header extends React.Component {

  onAppNotificationSelect = () => {

    this.setState({
      // appNotification: !this.state.appNotification
    })
  };
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification
    })
  };
  onLangSwitcherSelect = (event) => {
    this.setState({
      langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
    })
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    })
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps
    })
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    })
  };
  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      // userInfo: false,
      mailNotification: false,
      // appNotification: false,
      searchBox: false,
      apps: false
    });
  };
  handleCloseNotices = (e) => {
    this.setState({appNotification: !this.state.appNotification});
  }
  onToggleCollapsedNav = (e) => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };
  Apps = () => {
    return (
        <ul className="jr-list jr-list-half">
          <li className="jr-list-item">
            <Link className="jr-list-link" to="/app/calendar/basic">
              <i className="zmdi zmdi-calendar zmdi-hc-fw"/>
              <span className="jr-list-text"><IntlMessages id="sidebar.calendar.basic"/></span>
            </Link>
          </li>

          <li className="jr-list-item">
            <Link className="jr-list-link" to="/app/to-do">
              <i className="zmdi zmdi-check-square zmdi-hc-fw"/>
              <span className="jr-list-text"><IntlMessages id="sidebar.appModule.toDo"/></span>
            </Link>
          </li>

          <li className="jr-list-item">
            <Link className="jr-list-link" to="/app/mail">
              <i className="zmdi zmdi-email zmdi-hc-fw"/>
              <span className="jr-list-text"><IntlMessages id="sidebar.appModule.mail"/></span>
            </Link>
          </li>

          <li className="jr-list-item">
            <Link className="jr-list-link" to="/app/chat">
              <i className="zmdi zmdi-comment zmdi-hc-fw"/>
              <span className="jr-list-text"><IntlMessages id="sidebar.appModule.chat"/></span>
            </Link>
          </li>

          <li className="jr-list-item">
            <Link className="jr-list-link" to="/app/contact">
              <i className="zmdi zmdi-account-box zmdi-hc-fw"/>
              <span className="jr-list-text"><IntlMessages id="sidebar.appModule.contact"/></span>
            </Link>
          </li>

          <li className="jr-list-item">
            <Link className="jr-list-link" to="/">
              <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw"/>
              <span className="jr-list-text">Add New</span>
            </Link>
          </li>
        </ul>)
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false,
      walletOpen: false,
      walletCollapsed: false
    }
    this.handleCloseNotices = this.handleCloseNotices.bind(this);

  }
  componentDidMount() {
    if  (this.props.width < MOBILE_MAX_WIDTH && this.props.drawerType === MINI_DRAWER){
      this.props.setDrawerType(FIXED_DRAWER);
      this.props.toggleCollapsedNav(false);

    }
  }

  updateSearchText(evt) {
    this.setState({
      searchText: evt.target.value,
    });
  }
  handleOpenWallet() {
    this.setState({walletOpen: !this.state.walletOpen})
  }
  handleClose() {
    this.setState({walletOpen: false})
  }
  setMiniDrawer = () => {
    const {drawerType} = this.props;
    if (drawerType === FIXED_DRAWER){
      this.props.setDrawerType(MINI_DRAWER);
    }
    else
      this.props.setDrawerType(FIXED_DRAWER);

  };
  changeDarkMode = (setDark) => {
    const {darkTheme} = this.props;
    if(setDark !== darkTheme) {
      this.props.setDarkTheme();
      const body = document.body.classList;
      body.toggle('dark-theme')
    }
  }

  render() {
    const {drawerType, locale, darkTheme, navigationStyle, horizontalNavPosition, currentUser, width} = this.props;
    const {walletOpen, walletCollapsed} = this.state;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-block d-xl-none' : drawerType.includes(COLLAPSED_DRAWER) ? 'd-block' : 'd-none';
    // console.log("===>currentUser", currentUser)
    return (
        <AppBar className={`app-main-header ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>
          <Toolbar className="app-toolbar" disableGutters={false}>
            {/*<li className="list-inline-item ">*/}
            {/*  <IconButton className="header-icon-btn size-60" onClick={width < MOBILE_MAX_WIDTH ? this.onToggleCollapsedNav : this.setMiniDrawer.bind(this)}>*/}
            {/*    <img alt="alt" className="header-img-menu" src={require("../../../assets/images/header/menu-all.svg")}/>*/}
            {/*  </IconButton>*/}
            {/*</li>*/}
            {navigationStyle === HORIZONTAL_NAVIGATION ?
                <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
                            <span className="jr-menu-icon">
                              <span className="menu-icon"/>
                            </span>
                </div>
                :
                <div className="header-logo-wrapper">
         <span className="logo">
              {ImageStorage.header.logo(darkTheme, 'logo-img')}
                 <IconButton className={drawerType === FIXED_DRAWER ? "swipe-btn fixed-swipe-btn" : "swipe-btn" } onClick={this.onToggleCollapsedNav}>
                   <img alt="alt"  src={require("../../../assets/images/header/double-arrow.svg")}/>
              </IconButton>
          </span>

                </div>
                // <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                //             onClick={this.onToggleCollapsedNav}>
                //   <span className="menu-icon"/>
                // </IconButton>
            }

            <div className="header-wallet" aria-controls="simple-menu" aria-haspopup="true" onClick={width < TABLET_MIN_WIDTH ? this.handleOpenWallet.bind(this) : ""}>
              <Dropdown
                  className="quick-menu"
                  isOpen={walletOpen}
                  toggle={this.handleOpenWallet.bind(this)}>

                <DropdownToggle hidden={true}
                                className="d-inline-block"
                                tag="span"
                                data-toggle="dropdown">

                </DropdownToggle>

                <DropdownMenu right={false} >
                  <div className="dropdown-frozen-wrapper">
                    <div className="frozen-balance-wrapper">
                      <span className="balance-text"><IntlMessages id="panel.header.wallet_frozen_balance"/>:</span>
                      <span className="header-balance">
                    {parseInt(currentUser.wallet.frozen_balance / 10).toLocaleString()}
                        <span className="metric" title='تومان'>T</span>
                  </span>
                    </div>
                  </div>
                  <div className="drop-down-buttons-wrapper">
                    <Button
                        className="deposit-btn"
                        variant="contained"
                        color="default"
                        onClick={() => this.props.switchDepositDialog({state: true})}
                        // startIcon={<ArrowRightAltIcon className="deposit-icon" />}
                    >
             <span className="deposit-label">
               <IntlMessages id="panel.header.deposit_btn"/>
             </span>
                    </Button>
                    <Button
                        className="withdraw-btn"
                        variant="contained"
                        color="default"
                        onClick={() => this.props.switchWithdrawDialog({state: true})}
                        // startIcon={<ArrowRightAltIcon className="withdraw-icon" />}
                    >
              <span className="withdraw-label">
                              <IntlMessages id="panel.header.withdraw_btn"/>

              </span>
                    </Button>

                  </div>
                </DropdownMenu>
              </Dropdown>
              <div className={"wallet_wrapper"}>
                <div className="balance-wrapper">
                  <span className="balance-text"><IntlMessages id="panel.header.wallet_balance"/>:</span>
                  <span className="header-balance">
                    {parseInt(currentUser.wallet.balance / 10).toLocaleString()}
                    <span className="metric" title='تومان'>T</span>
                  </span>
                </div>

                {walletCollapsed && currentUser.wallet.frozen_balance > 0 ?
                    <div className="frozen-balance-wrapper">
                      <span className="balance-text"><IntlMessages id="panel.header.wallet_frozen_balance"/>:</span>
                      <span className="header-balance">
                    {parseInt(currentUser.wallet.frozen_balance / 10).toLocaleString()}
                        <span className="metric" title='تومان'>T</span>
                  </span>
                    </div> : ""
                }
                {(width < TABLET_MIN_WIDTH) || (currentUser.wallet.frozen_balance <= 0) ? "" :
                    <IconButton className="wallet-collapse-btn" onClick={() => this.setState({walletCollapsed : !walletCollapsed})}>
                      <NavigateBeforeIcon className={walletCollapsed ? "wallet-chevron collapsed" : "wallet-chevron"}/>
                    </IconButton>
                }


                   <Button
                    className="deposit-btn"
                    variant="contained"
                    color="default"
                    onClick={() => this.props.switchDepositDialog({state: true})}
                    // startIcon={<ArrowRightAltIcon className="deposit-icon" />}
                >
             <span className="deposit-label">
               <IntlMessages id="panel.header.deposit_btn"/>
             </span>
                </Button>
                <Button
                    className="withdraw-btn"
                    variant="contained"
                    color="default"
                    onClick={() => this.props.switchWithdrawDialog({state: true})}
                    // startIcon={<ArrowRightAltIcon className="withdraw-icon" />}
                >
              <span className="withdraw-label">
                              <IntlMessages id="panel.header.withdraw_btn"/>

              </span>
                </Button>
                {
                  width < TABLET_MIN_WIDTH ?  <ArrowDropDownIcon/> : ""
                }
              </div>
              {/*<img alt="alt" src={require("../../../assets/images/header/moon.svg")}/>*/}

            </div>
            <ul className="panel-header-notifications list-inline ">
              {/*<li className="list-inline-item">*/}
              {/*  <Dropdown*/}
              {/*      className="quick-menu"*/}
              {/*      isOpen={this.state.langSwitcher}*/}
              {/*      toggle={this.onLangSwitcherSelect.bind(this)}>*/}

              {/*    <DropdownToggle*/}
              {/*        className="d-inline-block"*/}
              {/*        tag="span"*/}
              {/*        data-toggle="dropdown">*/}
              {/*      <IconButton className="icon-btn" >*/}
              {/*        <i className={`flag flag-24 flag-${locale.icon}`}/>*/}
              {/*      </IconButton>*/}
              {/*    </DropdownToggle>*/}

              {/*    <DropdownMenu right className="w-50 d-none">*/}
              {/*      <LanguageSwitcher switchLanguage={this.props.switchLanguage}*/}
              {/*                        handleRequestClose={this.handleRequestClose}/>*/}
              {/*    </DropdownMenu>*/}
              {/*  </Dropdown>*/}


              {/*</li>*/}
              <li onClick={(e) => darkTheme ? this.changeDarkMode(false) : this.changeDarkMode(true)} className="list-inline-item mx-1 app-tour">
                {ImageStorage.header.darkMode(darkTheme, 'mode-btn')}
              </li>
              {/*<li className="list-inline-item ">*/}
              {/*  <IconButton className="header-icon-btn size-60" onClick={width < MOBILE_MAX_WIDTH ? this.onToggleCollapsedNav : this.setMiniDrawer.bind(this)}>*/}
              {/*    <img alt="alt" className="header-img-menu" src={require("../../../assets/images/header/sun.svg")}/>*/}
              {/*  </IconButton>*/}
              {/*</li>*/}
              <li className="list-inline-item mx-1 app-tour">
                <Dropdown
                    className="quick-menu"
                    isOpen={this.state.appNotification}
                    toggle={this.handleCloseNotices}>

                  <DropdownToggle
                      className="d-inline-block"
                      tag="span"
                      data-toggle="dropdown">
                    <IconButton size="medium" className="header-icon-btn">
                      {this.props.hasUnseenUserNotice ?
                          <Badge  badgeContent={this.props.unSeenNoticesCount} color={'error'}>
                            {ImageStorage.header.notification(darkTheme, 'header-img-notif')}
                          </Badge>
                          :
                          <Badge invisible={true}>
                            {ImageStorage.header.notification(darkTheme, 'header-img-notif')}
                          </Badge>
                      }

                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right  className="notice-dropdown"  persist={true} >
                    {/*<CardHeader  styleName="align-items-center notification-card"*/}
                    {/*             heading={<IntlMessages id="appNotification.title"/>}/>*/}
                    <AppNotification/>
                  </DropdownMenu>
                </Dropdown>
              </li>
              {/*{navigationStyle === HORIZONTAL_NAVIGATION &&*/}
              <li className="list-inline-item mx-1 user-nav">
                <Dropdown
                    className="quick-menu"
                    isOpen={this.state.userInfo}
                    toggle={this.onUserInfoSelect}>

                  <DropdownToggle
                      className="d-inline-block"
                      tag="span"
                      data-toggle="dropdown">
                    <IconButton className="icon-btn size-30">
                      {ImageStorage.header.account(darkTheme, 'user-header')}
                    </IconButton>
                  </DropdownToggle>

                  <DropdownMenu right persist={true}>
                    <UserInfoPopup/>
                  </DropdownMenu>
                </Dropdown>
              </li>
              {/*}*/}
            </ul>
            {/*<div className="header-logo">*/}
            {/*  <img alt="alt" src={require("../../../assets/images/logo/Logo.png")}/>*/}
            {/*</div>*/}

          </Toolbar>

        </AppBar>
    );
  }

}


const mapStateToProps = ({settings, notice, auth}) => {
  const {currentUser} = auth;
  const {drawerType, locale, navigationStyle, horizontalNavPosition, navCollapsed, width, darkTheme} = settings;
  const {hasUnseenUserNotice, unSeenNoticesCount} = notice;
  return {drawerType, locale, navigationStyle, horizontalNavPosition, hasUnseenUserNotice, darkTheme, unSeenNoticesCount, currentUser, navCollapsed, width}
};

export default withRouter(connect(mapStateToProps,
    {switchDepositDialog, switchWithdrawDialog, toggleCollapsedNav, switchLanguage, setDrawerType, setDarkTheme
})(Header));